import { Button, Form, Input, Checkbox } from 'antd'
import styles from './Login.module.css'

interface LoginForm {
  email: string
  password: string
}
type Props = {
  onSubmit: (values: LoginForm) => void
}

const Login = ({ onSubmit }: Props) => (
  <div className={styles.loginWrapper}>
    <div className={styles.container}>
      <h1 style={{ fontSize: 35, fontWeight: 600 }}>გაგუას კლინიკა</h1>
      <p style={{ marginTop: 30, marginBottom: 30 }}>
        კეთილი იყოს თქვენი დაბრუნება! დალოგინდით სისტემაში
      </p>
      <div className={styles.formWrapper}>
        <Form layout="vertical" name="login-user" onFinish={onSubmit}>
          <Form.Item label="ელ.ფოსტა" name="email" required>
            <Input />
          </Form.Item>
          <Form.Item label="პაროლი" name="password" required>
            <Input type="password" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>დამიმახსოვრე</Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            შესვლა
          </Button>
        </Form>
      </div>
    </div>
  </div>
)

export default Login
