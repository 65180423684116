/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// firebase.initializeApp({
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// })

// production conifgs

const firebaseConfig = {
  apiKey: 'AIzaSyCiMwMc2ycXi8HLViaGMggEN_EZXfOK1eA',
  authDomain: 'gagua-android.firebaseapp.com',
  databaseURL: 'https://gagua-android.firebaseio.com',
  projectId: 'gagua-android',
  storageBucket: 'gagua-android.appspot.com',
  messagingSenderId: '309400269467',
  appId: '1:309400269467:web:09c974798b2f8919518c3e',
  measurementId: 'G-GL1MNX8DGQ',
}

// const firebaseConfig = {
//   apiKey: 'AIzaSyCiSTyAfZts4lUuVqA7zZJMeoB0VvaOCpg',
//   authDomain: 'gagua-dev.firebaseapp.com',
//   projectId: 'gagua-dev',
//   storageBucket: 'gagua-dev.appspot.com',
//   messagingSenderId: '444572251689',
//   appId: '1:444572251689:web:3532b2aa181e12ac9fce38',
//   measurementId: 'G-NER5WMG7SY',
// }
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const db = firebase

export interface IUser extends firebase.User {}

// export default app
