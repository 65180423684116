import { ClipLoader } from 'react-spinners'

import styles from './Loader.module.css'

const Loader = () => (
  <div className={styles.loaderWrapper}>
    <div className={styles.loaderContainer}>
      <ClipLoader size={70} color="rgb(19, 207, 19)" />
    </div>
    <div className={styles.iconContainer}>
      <div>
        <img
          src="https://gaguaclinic.ge/assets/site/layout/images/logo-geo.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
)

export default Loader
