import { FC } from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import { useAuth } from 'context/AuthContext'

import Loader from 'components/Loader'

interface Props extends RouteProps {
  component: FC<RouteComponentProps>
}

const PrivateRoute = (props: Props) => {
  const { component: RouteComponent, ...rest } = props
  const { loading, currentUser } = useAuth()
  if (loading) {
    return <Loader />
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default PrivateRoute
