import { useState, createElement } from 'react'
import { Link } from 'react-router-dom'

import { Layout, Menu } from 'antd'
import {
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineHome,
} from 'react-icons/ai'

import styles from './Header.module.css'

const Aside = () => {
  const [collapsed, setCollapsed] = useState(true)
  const toggle = () => {
    setCollapsed(!collapsed)
  }
  return (
    <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
      <div className={`${collapsed ? styles.trigger : styles.triggerActive}`}>
        {createElement(collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
          className: styles.triggerIcon,
          onClick: toggle,
        })}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={['/']}
        defaultOpenKeys={['/']}
      >
        <Menu.Item key="/" icon={<AiOutlineHome />}>
          <Link to="/">მთავარი</Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  )
}

export default Aside
