import { Route, Switch } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'

import SignIn from 'pages/signIn'
import Home from 'pages/home'
import Profile from 'pages/profile'

function App() {
  return (
    <main className="main">
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        {/* <Route exact path="/" component={Home} /> */}
        <PrivateRoute exact path="/profile" component={Profile} />
        <Route exact path="/login" component={SignIn} />
      </Switch>
    </main>
  )
}

export default App
