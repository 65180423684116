import { Redirect, useHistory } from 'react-router'
import { message } from 'antd'
import { useAuth } from 'context/AuthContext'
import { auth } from 'firebaseService'

import { Login } from 'components/Auth'

import CoverImage from 'assets/image/cover.jpg'
import styles from './SignIn.module.css'

interface LoginForm {
  email: string
  password: string
}

const SignIn = () => {
  const { currentUser } = useAuth()

  const history = useHistory()
  const login = async (values: LoginForm) => {
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password)
      history.push('/')
    } catch (err) {
      message.error('ელ.ფოსტა ან პარილი არასწორია!')
    }
  }
  if (currentUser) {
    return <Redirect to="/" />
  }
  return (
    <section>
      <div className={styles.loginContainer}>
        <div className={styles.loginCoverContainer}>
          <img src={CoverImage} alt="cover" />
        </div>
        <Login onSubmit={login} />
      </div>
    </section>
  )
}

export default SignIn
