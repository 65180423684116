import { FC, createContext, useContext, useState, useEffect } from 'react'

import { auth, IUser } from 'firebaseService'

interface IAuthContext {
  currentUser: IUser | null
  loading: boolean
  logout: () => void
}

const AuthContext = createContext<IAuthContext>({
  currentUser: null,
  loading: true,
  logout: () => {},
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const unsibscibe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsibscibe
  }, [])

  return (
    <AuthContext.Provider
      value={{ currentUser, loading, logout: () => auth.signOut() }}
    >
      {children}
    </AuthContext.Provider>
  )
}
