// import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import { Layout } from 'antd'
import firebase from 'firebase'

import { db } from 'firebaseService'
import HomeTable from 'components/Datatable/HomeTable'
import { Header, Aside } from 'components/Header'
import { IAppUser } from 'interfaces'

const Home = () => {
  const [isLoading, setLoading] = useState(true)
  const [users, setUsers] = useState<IAppUser[]>([])

  const callApiQuery = (dbRef: firebase.database.Query) =>
    dbRef.once('value', (snapshot) => {
      // const totalRecord = snapshot.numChildren()
      const data = snapshot.val()

      if (data) {
        const dbUsers = Object.keys(data).map((key) => {
          const entry = data[key]
          return {
            id: key,
            ...entry,
          }
        })
        setUsers(dbUsers)
      } else {
        setUsers([])
      }
      setLoading(false)
    })

  const handleEmailSearch = (email: string) => {
    setLoading(true)
    let userRef = db.database().ref('users/').orderByChild('mail')
    if (email && email.length > 1) {
      userRef = db.database().ref('users/').orderByChild('mail').equalTo(email)
    }
    return callApiQuery(userRef)
  }
  const handleFullNameSearch = (fullName: string) => {
    setLoading(true)
    let userRef = db.database().ref('users/').orderByChild('fullName')
    if (fullName && fullName.length > 1) {
      userRef = db
        .database()
        .ref('users/')
        .orderByChild('fullName')
        .startAt(fullName)
        .endAt(`${fullName}\uf8ff`)
    }
    return callApiQuery(userRef)
  }
  const handleBothSearch = (fullName: string, email: string) => {
    setLoading(true)
    let userRef = db.database().ref('users/').orderByChild('fullName')
    if (fullName && fullName.length > 1) {
      userRef = db.database().ref('users/').orderByChild('mail').equalTo(email)
    }
    return userRef.once('value', (snapshot) => {
      // const totalRecord = snapshot.numChildren()
      const data = snapshot.val()

      if (data) {
        const dbUsers = Object.keys(data).map((key) => {
          if (data[key].fullName.includes(fullName)) {
            const entry = data[key]
            return {
              id: key,
              ...entry,
            }
          }
          return null
        })
        setUsers(dbUsers)
      } else {
        setUsers([])
      }
      setLoading(false)
    })
  }

  if (isLoading) {
    document.body.classList.add('loading')
  }

  useEffect(() => {
    const getData = () => {
      // let userRef = db
      //   .database()
      //   .ref('users/')
      //   .orderByChild('mail')

      const userRef = db.database().ref('users/').orderByChild('mail')

      return userRef.once('value', (snapshot) => {
        // const totalRecord = snapshot.numChildren()

        const data = snapshot.val()

        if (data) {
          const dbUsers = Object.keys(data).map((key) => {
            const entry = data[key]
            return {
              id: key,
              ...entry,
            }
          })
          setUsers(dbUsers)
        } else {
          setUsers([])
        }
        setLoading(false)
      })
    }
    getData()
  }, [])

  return (
    <Layout className="main-layout">
      <Aside />
      <Layout>
        <Header />
        <HomeTable
          dataSource={users}
          isLoading={isLoading}
          handleEmailSearch={handleEmailSearch}
          handleFullNameSearch={handleFullNameSearch}
          handleBothSearch={handleBothSearch}
        />
      </Layout>
    </Layout>
  )
}

export default Home
