/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useState } from 'react'
import { Table } from 'antd'

import { AiOutlineUser } from 'react-icons/ai'

import { IAppUser, PregnancyDate } from 'interfaces'
import styles from './HomeTable.module.css'

type Props = {
  dataSource: IAppUser[]
  isLoading: boolean
  handleShowModal: (record: IAppUser) => void
}
const filterDate = (dateString?: string) =>
  dateString
    ? new Date(dateString).toLocaleString('ka-GE', {
        weekday: 'short',
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
    : ''

const Datatable = ({
  dataSource,
  isLoading = false,
  handleShowModal,
}: Props) => {
  const [filteredInfo, setFilterInfo] = useState({
    userType: null,
  })
  const onChange = (pagination: any, filters: any, sorter: any) => {
    // console.log(filters)
    setFilterInfo(filters)
  }
  const columns = [
    {
      title: 'სახელი გვარი',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'ელ.ფოსტა',
      dataIndex: 'mail',
      key: 'mail',
    },
    {
      title: 'მობილური',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'ტიპი',
      dataIndex: 'userType',
      key: 'userType',
      filters: [
        { text: 'pregnant', value: 'pregnant' },
        { text: 'guest', value: 'guest' },
        { text: 'parent', value: 'parent' },
      ],
      filteredValue: filteredInfo.userType || null,
      onFilter: (value, record) =>
        record.userType && record.userType.includes(value),
    },
    {
      title: 'სავარაუდო თარიღი',
      dataIndex: 'PregnancyDates',
      key: 'PregnancyDates',
      width: 450,
      render: (dates: PregnancyDate) => {
        if (dates) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                <b>ბოლო პერიოდი</b>:{` ${filterDate(dates.lastPeriod)}`}
              </span>
              <span>
                <b>კვირა 39:</b>
                {` ${filterDate(dates.week39)}`}
              </span>
              <span>
                <b>კვირა 40:</b>
                {` ${filterDate(dates.week40)}`}
              </span>
            </div>
          )
        }
        return <span>არ არის შევსებული</span>
      },
    },
    {
      title: 'დეტალურად',
      dataIndex: 'detail',
      render: (_text: string, record: IAppUser) => (
        <>
          <button
            type="button"
            style={{
              cursor: 'pointer',
              marginLeft: '15px',
              border: 'none',
              background: 'none',
            }}
            onClick={() => handleShowModal(record)}
          >
            <AiOutlineUser style={{ fontSize: 23, color: '#40a74f' }} />
          </button>
        </>
      ),
    },
  ]

  return (
    <div className={styles.table}>
      <Table
        size="small"
        scroll={{ y: 600 }}
        loading={isLoading}
        dataSource={dataSource || []}
        columns={columns}
        onChange={onChange}
      />
    </div>
  )
}

export default memo(Datatable)
