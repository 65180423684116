import { Layout, Menu, Avatar } from 'antd'
import { useAuth } from 'context/AuthContext'
import styles from './Header.module.css'

const { SubMenu } = Menu

const Header = () => {
  // const firstWord = user.fullname ? user.fullname.slice(0, 1) : ''
  const { logout } = useAuth()

  const handleClickMenu = () => {
    logout()
  }
  return (
    <Layout.Header className={styles.header}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Menu
          key="user"
          mode="horizontal"
          onClick={handleClickMenu}
          style={{ height: 65 }}
        >
          <SubMenu
            title={
              <>
                <span
                  style={{
                    color: '#999',
                    marginRight: 4,
                  }}
                >
                  გამარჯობა,
                </span>
                <span style={{ fontFamily: 'sans-serif' }}>Admin</span>
                <Avatar
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#001529',
                    fontFamily: 'sans-serif',
                  }}
                >
                  A
                </Avatar>
              </>
            }
          >
            <Menu.Item key="SignOut">გასვლა</Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </Layout.Header>
  )
}

export default Header
