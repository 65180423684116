/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react'
import { Button, Form, FormInstance, Input } from 'antd'

// const { RangePicker } = DatePicker

interface FormProps {
  email: string
  // date: [any]
  fullName: string
}
type FilterProps = {
  handleEmailSearch: (email: string) => void
  handleFullNameSearch: (fullName: string) => void
  handleBothSearch: (fullName: string, email: string) => void
}

const UserFilter = ({
  handleEmailSearch,
  handleFullNameSearch,
  handleBothSearch,
}: FilterProps) => {
  const formRef = useRef<FormInstance>(null)
  const onFinish = (values: FormProps) => {
    if (
      values.email &&
      values.email.trim().length > 5 &&
      values.fullName &&
      values.fullName.trim().length > 2
    ) {
      handleBothSearch(values.fullName.trim(), values.email.trim())
    } else if (values.fullName && values.fullName.trim().length > 2) {
      handleFullNameSearch(values.fullName.trim())
    } else if (values.email && values.email.trim().length > 5) {
      handleEmailSearch(values.email.trim())
    }
  }
  const handleReset = () => {
    formRef.current?.resetFields()
    handleEmailSearch('')
  }
  return (
    <div>
      <Form ref={formRef} layout="inline" onFinish={onFinish}>
        <Form.Item name="email">
          <Input placeholder="ელ.ფოსტა" />
        </Form.Item>
        {/* <Form.Item name="date">
          <RangePicker
            name="date"
            placeholder={['დასაწუისის დღე', 'დასასრულის დღე']}
          />
        </Form.Item> */}
        <Form.Item name="fullName">
          <Input placeholder="სახელი გვარი" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            გაფილტვრა
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="default" danger htmlType="button" onClick={handleReset}>
            გასუფთავება
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default UserFilter
