/* eslint-disable react/require-default-props */
import { Image, Modal } from 'antd'
import { IAppUser } from 'interfaces'
import { fallback } from './helper'

type Props = {
  onClose: () => void
  isOpen: boolean
  userRecord?: IAppUser
}
type GridItemProps = {
  imgSrc?: string
}

const GridItem = ({ imgSrc }: GridItemProps) => (
  <Image
    width="full"
    src={imgSrc && imgSrc.length > 10 ? imgSrc : 'error'}
    fallback={fallback}
  />
)

const UserModal = ({ onClose, isOpen, userRecord }: Props) => (
  <Modal
    title="მომხმარებლის შესახებ"
    onCancel={onClose}
    visible={isOpen}
    width={860}
    okButtonProps={{ hidden: true }}
  >
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '250px 250px 250px',
        gridGap: 30,
      }}
    >
      <div>
        <h3>თვე 1</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m1} />
      </div>

      <div>
        <h3>თვე 2</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m2} />
      </div>
      <div>
        <h3>თვე 3</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m3} />
      </div>
      <div>
        <h3>თვე 4</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m4} />
      </div>
      <div>
        <h3>თვე 5</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m5} />
      </div>
      <div>
        <h3>თვე 6</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m6} />
      </div>
      <div>
        <h3>თვე 7</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m7} />
      </div>
      <div>
        <h3>თვე 8</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m8} />
      </div>
      <div>
        <h3>თვე 9</h3>
        <GridItem imgSrc={userRecord?.PregnancyImages?.m9} />
      </div>
    </div>
  </Modal>
)

export default UserModal
