import { memo, useState } from 'react'
import { Layout } from 'antd'
import { IAppUser } from 'interfaces'

import { UserFilter } from 'components/Filter'
import UserModal from 'components/Modal/UserModal'
import Datatable from './Datatable'

import styles from './HomeTable.module.css'

type Props = {
  dataSource: IAppUser[]
  isLoading: boolean
  handleEmailSearch: (email: string) => void
  handleFullNameSearch: (fullName: string) => void
  handleBothSearch: (fullName: string, email: string) => void
}

const HomeTable = (props: Props) => {
  const [isModalOpen, setOpen] = useState<boolean>(false)
  const [userRecord, setUserRecord] = useState<IAppUser>()
  const handleShowModal = (record: IAppUser) => {
    setUserRecord(record)
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
    setUserRecord(undefined)
  }

  return (
    <Layout.Content className="content">
      <div className={styles.tableLayout}>
        <UserFilter
          handleEmailSearch={props.handleEmailSearch}
          handleFullNameSearch={props.handleFullNameSearch}
          handleBothSearch={props.handleBothSearch}
        />
        <Datatable {...props} handleShowModal={handleShowModal} />
      </div>
      <UserModal
        onClose={onClose}
        isOpen={isModalOpen}
        userRecord={userRecord}
      />
    </Layout.Content>
  )
}

export default memo(HomeTable)
